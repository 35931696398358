import React, {useState, useEffect} from "react";
import useGet from "../../hooks/useGet";
import { hasData } from "../../utils/handler.utils";
import { ucBalanceShee } from "../../utils/reactTableColumns";
import ReactTable from '../ReactTables/ReactTable';
import UiHandler from "../UiHandler";

export default function CardUc() {
  const [data, loading, error] = useGet('/admin/get-uc-balance-sheet/162');

  return (
    <>
      <div className="relative flex flex-col min-w-0 break-words bg-white w-full shadow-lg rounded">
        <div className="rounded-t mb-0 px-4 py-3 border-0">
          <div className="flex flex-wrap items-center">
            <div className="relative w-full px-4 max-w-full flex-grow flex-1">
              <h3 className="font-semibold text-base text-blueGray-700">
                UC Balance Sheet
              </h3>
            </div>
            <div className="relative w-full px-4 max-w-full flex-grow flex-1 text-right">
              
            </div>
          </div>
        </div>
        <div className="block w-full overflow-x-auto">
          <UiHandler absoluteLoader={true} data={data} loading={loading} error={error} />
          {hasData(data, loading, error) && <ReactTable tableId="uc_balance_sheet" data={data.uc_added} columns={ucBalanceShee} />}
        </div>
      </div>
    </>
  );
}
